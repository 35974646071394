@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100%;
  width: 100%;
}

body {
  height: 100%;
  width: 100%;
  color: #fff;
}

#root,
.App {
  height: 100%;
  width: 100%;
}

* {
  box-sizing: border-box;
  font-family: "Inter";
}

p,
span,
div,
h1,
h2,
h3,
h4,
h5,
h6 {
  cursor: default;
}

input {
  display: flex;
  color: #000;
  background: #fff;
  height: 52px;
  padding: 14px 16px;
  font-size: 14px;
  border-radius: 8px;
  gap: 10px;
  border: 2px solid #e3c4a3;
  &:focus {
    outline: none;
  }
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  background-color: transparent !important; /* Remove background color */
  transition: background-color 5000s ease-in-out 0s; /* Prevent the background from flashing */
}

input:-webkit-autofill:focus,
textarea:-webkit-autofill:focus,
select:-webkit-autofill:focus {
  background-color: transparent !important;
}

button {
  background: none;
  border: "none";
  color: #fff;
  &:focus {
    outline: none;
  }
}

.gradient-text {
  background: linear-gradient(180deg, #ffd07a 0%, #ffcf60 39%, #ffa93f 69%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

